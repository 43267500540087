import { utils } from '../helpers/utils';
import { apiHandler } from '../helpers/api-handler';

export const userService = {
    create,
    list,
    get,
    update,
    getUserProfiles
};

function list() {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/user/list')
        .then(response => {
            return response;
    });
}

function get(id) {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/user/get?id=' + id)
        .then(response => {
            return response;
    });
}

function create(user) {

    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/user/create', user)
        .then(response => {
            return response;
    });
}

function update(user) {
    
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/user/update', user)
        .then(response => {
            return response;
    });
}

function getUserProfiles() {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/user/getUserProfiles')
        .then(response => {
            return response;
    });
}