<template>
  <div>
    <title-header title="TENANTS CADASTRADOS"><q-btn class="custom-button" label="Novo" no-caps @click="createTenant" /></title-header>
    <div class="q-pa-md">
        <q-table
           
        flat bordered
        class="my-sticky-header-table"
        dense
        :rows="rows"
        :columns="columns"
        :pagination="{ rowsPerPage: 0 }"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="name" :props="props">
                {{ props.row.name }}
              </q-td>
              <q-td key="active" :props="props">
                 {{ $formatBooleanValue(props.row.active) }}
              </q-td>
              <q-td key="operations" :props="props">
                <router-link :to="'/system/TenantForm/' + props.row.id">
                  <q-btn color="primary"
                          icon="edit"
                          size="15px"
                          flat
                          dense
                           />
                </router-link>
                <router-link :to="'/system/Dashboard/' + props.row.id">
                  <q-btn color="primary"
                          icon="dashboard"
                          size="15px"
                          flat
                          dense
                          style="margin-left: 20px;" />
                </router-link>
                <q-btn color="primary"
                          v-if="isFeatureEnabled('dateAdjustmentFeature') && props.row.enableDateAdjustmentFeature"
                          icon="update"
                          size="15px"
                          flat
                          dense
                          @click="openAdjustDatesModal(props.row.id)"
                          style="margin-left: 20px;" />
              </q-td>
            </q-tr>
          </template>
        </q-table>

    </div>
    <q-dialog v-model="open_adjust_dates_modal">
      <dialog-wrapper title="Reajuste de data">
        <div style="padding-left:20px">
          <div class="row" style="background-color: antiquewhite; padding:20px;">
            <div class="col-3" style="padding-top: 20px; font-weight: bold;">
              Atualizar todos os registros
            </div>
            <div class="col-2" style="padding-top: 15px;">
              <q-input dense filled type="number" v-model.number="days_all_records" label="Dias"/>
            </div>
            <div class="col-1" style="padding-top: 15px; padding-left:15px">
              <q-btn color="primary" @click="updateAllRecordsDate()" label="Atualizar" :loading="all_records_saving" :disable="!days_all_records || days_all_records == 0" />
            </div>
          </div>
          <br>
          <q-separator/>
          <br>
          <div class="row" style="padding:20px">
            <div class="col-3" style="padding-top: 20px;">
              Histórico de status
            </div>
            <div class="col-2" style="padding-top: 15px;">
              <q-input dense filled type="number" v-model.number="days_history_status" label="Dias"/>
            </div>
            <div class="col-1" style="padding-top: 15px; padding-left:15px">
              <q-btn color="primary" @click="updateHistoryStatus()" label="Atualizar" :loading="history_status_saving || all_records_saving" :disable="!days_history_status || days_history_status == 0" />
            </div>
          </div>
          <br>
          <q-separator/>
          <br>
          <div class="row" style="padding:20px">
            <div class="col-3" style="padding-top: 20px;">
              Propostas
            </div>
            <div class="col-2" style="padding-top: 15px;">
              <q-input dense filled type="number" v-model.number="days_proposal" label="Dias"/>
            </div>
            <div class="col-1" style="padding-top: 15px; padding-left:15px">
              <q-btn color="primary" @click="updateProposalDate()" label="Atualizar" :loading="proposal_saving || all_records_saving" :disable="!days_proposal || days_proposal == 0" />
            </div>
          </div>
          <br>
          <q-separator/>
          <br>
          <div class="row" style="padding:20px">
            <div class="col-3" style="padding-top: 20px;">
              Processos
            </div>
            <div class="col-2" style="padding-top: 15px;">
              <q-input dense filled type="number" v-model.number="days_process" label="Dias"/>
            </div>
            <div class="col-1" style="padding-top: 15px; padding-left:15px">
              <q-btn color="primary" @click="updateProcessDate()" label="Atualizar" :loading="process_saving || all_records_saving" :disable="!days_process || days_process == 0" />
            </div>
          </div>
          <br>
          <q-separator/>
          <br>
          <div class="row" style="padding:20px">
            <div class="col-3" style="padding-top: 20px;">
              Anexos
            </div>
            <div class="col-2" style="padding-top: 15px;">
              <q-input dense filled type="number" v-model.number="days_attachment" label="Dias"/>
            </div>
            <div class="col-1" style="padding-top: 15px; padding-left:15px">
              <q-btn color="primary" @click="updateAttachmentDate()" label="Atualizar" :loading="attachment_saving || all_records_saving" :disable="!days_attachment || days_attachment == 0" />
            </div>
          </div>
          <br>
          <q-separator/>
          <br>
          <div class="row" style="padding:20px">
            <div class="col-3" style="padding-top: 20px;">
              Ocorrências
            </div>
            <div class="col-2" style="padding-top: 15px;">
              <q-input dense filled type="number" v-model.number="days_incident" label="Dias"/>
            </div>
            <div class="col-1" style="padding-top: 15px; padding-left:15px">
              <q-btn color="primary" @click="updateIncidentDate()" label="Atualizar" :loading="incident_saving || all_records_saving" :disable="!days_incident || days_incident == 0" />
            </div>
          </div>
          <br>
          <q-separator/>
          <br>
          <div class="row" style="padding:20px">
            <div class="col-3" style="padding-top: 20px;">
              Dados financeiros
            </div>
            <div class="col-2" style="padding-top: 15px;">
              <q-input dense filled type="number" v-model.number="days_financial" label="Dias"/>
            </div>
            <div class="col-1" style="padding-top: 15px; padding-left:15px">
              <q-btn color="primary" @click="updateFinancialItemsDate()" label="Atualizar" :loading="financial_saving || all_records_saving" :disable="!days_financial || days_financial == 0" />
            </div>
          </div>
          <br>
          <q-separator/>
          <br>
          <div class="row" style="padding:20px">
            <div class="col-3" style="padding-top: 20px;">
              Cotações
            </div>
            <div class="col-2" style="padding-top: 15px;">
              <q-input dense filled type="number" v-model.number="days_quotation" label="Dias"/>
            </div>
            <div class="col-1" style="padding-top: 15px; padding-left:15px">
              <q-btn color="primary" @click="updateQuotationDate()" label="Atualizar" :loading="quotation_saving || all_records_saving" :disable="!days_quotation || days_quotation == 0" />
            </div>
          </div>
        </div>
          
      </dialog-wrapper>
    </q-dialog>
  </div>
  
</template>
<script>
import {tenantService} from '../services/tenant.service';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { isFeatureEnabled } from '@/helpers/feature-flags';
import DialogWrapper from '../components/DialogWrapper.vue';
import { Notify } from 'quasar';

const columns = [
  {
    name: 'name',
    label: 'Nome',
    align: 'center',
    field: row => row.name,
    format: val => `${val}`,
    sortable: true
  },
  { name: 'active', label: 'Ativo', align: 'center',field: 'active', sortable: true },
  { name: 'operations', label: '', field: '', sortable: true, align: "left" }
]


export default {
  components : {DialogWrapper},
  setup () {
    //Route
    const router = useRouter();

    //Data
    const rows = ref([]);

    //Adjust dates modal
    const open_adjust_dates_modal = ref(false);
    const selected_tenant = ref(null);
    const days_history_status = ref(null);
    const history_status_saving = ref(false);
    const days_proposal = ref(null);
    const proposal_saving = ref(false);
    const days_process = ref(null);
    const process_saving = ref(false);
    const days_attachment = ref(null);
    const attachment_saving = ref(false);
    const days_incident = ref(null);
    const incident_saving = ref(false);
    const days_financial = ref(null);
    const financial_saving = ref(false);
    const days_quotation = ref(null);
    const quotation_saving = ref(false);
    const days_all_records = ref(null);
    const all_records_saving = ref(false);


    //Methods
    const  getTenantList = () => {
      tenantService.listModel()
      .then((response) => {
          rows.value = response  
      });
    };

    const createTenant = () => {
      router.push('/system/tenantform') ;
    }

    const openAdjustDatesModal = (tenant) => {
      selected_tenant.value = tenant;
      open_adjust_dates_modal.value = true;
    }

    const updateHistoryStatus = () => {
      history_status_saving.value = true;
      tenantService.updateHistoryStatus(days_history_status.value)
        .then(() => {
           history_status_saving.value = false;
           days_history_status.value = null;

           Notify.create({
            message: 'Histórico de status atualizado com sucesso!',
            type: 'positive'
          });
        });
    }

    const updateProposalDate = () => {
      proposal_saving.value = true;
      tenantService.updateProposalDate(days_proposal.value)
        .then(() => {
           proposal_saving.value = false;
           days_proposal.value = null;

           Notify.create({
            message: 'Datas das propostas atualizadas com sucesso!',
            type: 'positive'
          });
        });
    }

    const updateProcessDate = () => {
      process_saving.value = true;
      tenantService.updateProcessDate(days_process.value)
        .then(() => {
          process_saving.value = false;
          days_process.value = null;

           Notify.create({
            message: 'Datas dos processos atualizadas com sucesso!',
            type: 'positive'
          });
        });
    }

    const updateAttachmentDate = () => {
      attachment_saving.value = true;
      tenantService.updateAttachmentDate(days_attachment.value)
        .then(() => {
          attachment_saving.value = false;
          days_attachment.value = null;

           Notify.create({
            message: 'Datas dos anexos atualizadas com sucesso!',
            type: 'positive'
          });
        });
    }

    const updateIncidentDate = () => {
      incident_saving.value = true;
      tenantService.updateIncidentDate(days_incident.value)
        .then(() => {
          incident_saving.value = false;
          days_incident.value = null;

           Notify.create({
            message: 'Datas dos incidentes atualizadas com sucesso!',
            type: 'positive'
          });
        });
    }

    const updateFinancialItemsDate = () => {
      financial_saving.value = true;
      tenantService.updateFinancialItemsDate(days_financial.value)
        .then(() => {
          financial_saving.value = false;
          days_financial.value = null;

           Notify.create({
            message: 'Datas dos dados financeiros atualizadas com sucesso!',
            type: 'positive'
          });
        });
    }

    const updateQuotationDate = () => {
      quotation_saving.value = true;
      tenantService.updateQuotationDate(days_quotation.value)
        .then(() => {
          quotation_saving.value = false;
          days_quotation.value = null;

           Notify.create({
            message: 'Datas das cotações atualizadas com sucesso!',
            type: 'positive'
          });
        });
    }

    const updateAllRecordsDate = () => {
      all_records_saving.value = true;
      tenantService.updateDatesForAllRecords(days_all_records.value)
        .then(() => {
          all_records_saving.value = false;
          days_all_records.value = null;

           Notify.create({
            message: 'Datas de todos os registros atualizadas com sucesso!',
            type: 'positive'
          });
        });
    }

    //Computed
    onMounted(() => {
        getTenantList();
      });
    return {
      columns,
      rows,
      open_adjust_dates_modal,
      days_history_status,
      history_status_saving,
      days_proposal,
      proposal_saving,
      days_process,
      process_saving,
      days_attachment,
      attachment_saving,
      days_incident,
      incident_saving,
      days_financial,
      financial_saving,
      days_quotation,
      quotation_saving,
      days_all_records,
      all_records_saving,
      createTenant,
      isFeatureEnabled,
      openAdjustDatesModal,
      updateHistoryStatus,
      updateProposalDate,
      updateProcessDate,
      updateAttachmentDate,
      updateIncidentDate,
      updateFinancialItemsDate,
      updateQuotationDate,
      updateAllRecordsDate
    }
  }
}
</script>
<style>
.my-sticky-header-table {
  /* height or max-height is important */
  height: 310px;
}

.my-sticky-header-table .q-table__top,
.my-sticky-header-table .q-table__bottom,
.my-sticky-header-table thead tr:first-child th {
  /* bg color is important for th; just specify one */
  background-color: #f48c0c;
}

.my-sticky-header-table thead tr th {
  position: sticky;
  z-index: 1;
}

.my-sticky-header-table thead tr:first-child th {
  top: 0;
  color: white;
  font-weight: bold;
}

/* this is when the loading indicator appears */
.my-sticky-header-table.q-table--loading thead tr:last-child th {
  /* height of all previous header rows */
  top: 48px;
}

/* prevent scrolling behind sticky top row on focus */
.my-sticky-header-table tbody {
  /* height of all previous header rows */
  scroll-margin-top: 48px;
}

</style>
