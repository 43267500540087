<template>
    <div>
      <TenantForm/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
import TenantForm from '../components/TenantForm.vue';
  
  export default {
    name: 'TenantFormView',
    components: {
        TenantForm
    }
  }
  </script>