<template>
    <q-toolbar class="titleHeader">
        <q-space></q-space>
        {{title}}
        <q-space></q-space>
        <span class="rightButton">
            <slot></slot>
        </span>
        
    </q-toolbar>
</template>
<style>
    .titleHeader {
        background-color: #94acc4;
        color: white;
        font-weight:bold;
        font-size:18px;
        text-transform:uppercase;
        font-family: 'Segoe UI';
    }

    .rightButton {
        padding-right: 0px;

    }

    .custom-button {
        background-color: white !important;
        color: #f48c0c;
        height: 100%;
        border: 0px;
        font-weight: bold;
        font-family: 'Segoe UI' !important;
        text-transform:uppercase;
        font-size:12px;
       
    }
</style>

<script>
export default {
   name: "titleHeader",
   props: ['title']
}
</script>