import { authService } from "../services/auth.service";
import { utils } from "./utils";

export const apiHandler =  {
    handleResponse,
    getApiRequest,
    getApiAnnonymousRequest,
    postApiRequest
}


function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function getApiRequest(url, headers = {}) {
    // Set default headers, these could be extended to include any headers passed to the function
    const defaultHeaders = {
        'Content-Type': 'application/json'
    };

    // Merge default headers with any custom headers provided
    headers = { ...defaultHeaders, ...headers };

    // Now we use makeApiRequest to handle the request, it takes care of tokens and renewals
    return makeApiRequest('GET', url, null, true, headers)
        .then(response => { return response})
        .catch(error => {
            console.error('GET API Request Failed:', error);
            throw error;
        });
}


function postApiRequest(url, body, headers = {}) {

    // Set default headers, these could be extended to include any headers passed to the function
    const defaultHeaders = {
        'Content-Type': 'application/json'
    };

    // Merge default headers with any custom headers provided
    headers = { ...defaultHeaders, ...headers };

    // Now we use makeApiRequest to handle the request, it takes care of tokens and renewals
    return makeApiRequest('POST', url, body, true, headers)
        .then(response => { return response})
        .catch(error => {
            console.error('POST API Request Failed:', error);
            throw error;
        });
}



function getApiAnnonymousRequest(url, headers = {}) {

    const defaultHeaders = { 
        'Content-Type': 'application/json'
    
    };
    headers = { ...defaultHeaders, ...headers };

    const requestOptions = {
        method: 'GET',
        headers
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .catch(error => {
            console.error('GET API Request Failed:', error);
            throw error;
        });
}

async function renewToken() {
   
    try {
        var tokenInfo = authService.getTokenSessionStorage();
        const refreshToken = tokenInfo.refresh_token;

        if (!refreshToken) {
            throw new Error("No refresh token available.");
        }

        const response = await fetch(utils.getApiUrl() + '/api/auth/RenewToken?refreshToken=' + refreshToken, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error("Failed to renew token");
        }

        const newTokenData = await response.json();
        authService.storeSessionStorage(newTokenData, newTokenData.user);

    } catch (error) {
        console.error("Error renewing token:", error);
        throw new Error("Token renewal failed");
    }
}


async function makeApiRequest(method, url, body = null, tryRenewToken = true) {
    
    let tokenInfo = authService.getTokenSessionStorage();

    if (!tokenInfo || !tokenInfo.id_token) {
        throw new Error("No token or refresh token found.");
    }

    const nowInSeconds = Math.floor(Date.now() / 1000); // Current time in seconds
    const tokenExpiration = tokenInfo.not_before + tokenInfo.id_token_expires_in;

    if (tokenExpiration < nowInSeconds) {
        if (tryRenewToken) {
             
            try {
                await renewToken();
                return makeApiRequest(method, url, body, false); // Retry request with new token
            } catch (renewError) {
                console.error("Token renewal failed:", renewError);
                redirectToLogin(); // Redirect to login if token renewal fails
                return;
            }
        } else {
            redirectToLogin(); // Redirect to login if token renewal is not allowed
            return;
        }
    }

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenInfo.id_token}`
    };

    const requestOptions = {
        method: method,
        headers: headers
    };

    if (body) {
        requestOptions.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            if (response.status === 401 && !tryRenewToken) {
                console.error("Unauthorized request on retry, redirecting to login...");
                redirectToLogin(); // Redirect to login if second attempt after token renewal fails with 401
                return;
            } else if (response.status === 401 && tryRenewToken) {
                console.error("401 received, trying to renew token...");
                await renewToken();
                return makeApiRequest(method, url, body, false); // Retry request with new token
            }
            throw new Error('Network response was not ok');
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            return await response.json(); 
        } else {

            if(contentType && contentType.includes("application/octet-stream")){
                return await response.blob();
            }

            return { message: "No content response" };
        }
    } catch (error) {
        console.error('API Request Failed:', error);
        throw error;
    }
}

function redirectToLogin() {
    window.location.href = '/?authExpired=true';
}





