<template>
  <div>
    <title-header title="USUÁRIOS CADASTRADOS"><q-btn class="custom-button" label="Novo" no-caps @click="createUser" /></title-header>
    <div class="q-pa-md">
        <q-table
           
        flat bordered
        class="my-sticky-header-table"
        dense
        :rows="rows"
        :columns="columns"
        :pagination="{ rowsPerPage: 0 }"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="name" :props="props">
                {{ props.row.name }}
              </q-td>
              <q-td key="tenants" :props="props">
                <div v-for="(tenantAssignment) in props.row.tenantAssignments" :key="tenantAssignment.id">
                  <q-badge color="primary">
                   {{ tenantAssignment.tenant.name }}
                  </q-badge>
                </div>
              </q-td>
              <q-td key="active" :props="props">
                 {{ $formatBooleanValue(props.row.active) }}
              </q-td>
              <q-td key="operations" :props="props">
                <router-link :to="'/system/userform/' + props.row.id">
                  <q-icon color="primary"
                          name="edit"
                          style="font-size: 2.0em" />
                </router-link>
              </q-td>
            </q-tr>
          </template>
        </q-table>

    </div>
  </div>
  
</template>
<script>
import { userService } from '../services/user.service';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';


const columns = [
  {
    name: 'name',
    label: 'Nome',
    align: 'center',
    field: row => row.name,
    format: val => `${val}`,
    sortable: true
  },
  { name: 'tenants', label: 'Tenants', field: '', sortable: true, align: "center" },
  { name: 'active', label: 'Ativo', align: 'center',field: 'active', sortable: true },
  { name: 'operations', label: '', field: '', sortable: true, align: "center" }
]


export default {
  setup () {
    //Route
    const router = useRouter();

    //Data
    const rows = ref([]);

    //Methods
    const  getUserList = () => {
      userService.list()
      .then((response) => {
          rows.value = response  
      });
    };

    const createUser = () => {
      router.push('/system/userform') ;
    }

    //Computed
    onMounted(() => {
        getUserList();
      });
    return {
      columns,
      rows,
      createUser
    }
  }
}
</script>
<style>
.my-sticky-header-table {
  /* height or max-height is important */
  height: 310px;
}

.my-sticky-header-table .q-table__top,
.my-sticky-header-table .q-table__bottom,
.my-sticky-header-table thead tr:first-child th {
  /* bg color is important for th; just specify one */
  background-color: #f48c0c;
}

.my-sticky-header-table thead tr th {
  position: sticky;
  z-index: 1;
}

.my-sticky-header-table thead tr:first-child th {
  top: 0;
  color: white;
  font-weight: bold;
}

/* this is when the loading indicator appears */
.my-sticky-header-table.q-table--loading thead tr:last-child th {
  /* height of all previous header rows */
  top: 48px;
}

/* prevent scrolling behind sticky top row on focus */
.my-sticky-header-table tbody {
  /* height of all previous header rows */
  scroll-margin-top: 48px;
}

</style>
