<template>
    <div class="card">
        <q-card class="my-card">
            <q-card-section>
                <div class="row">
                    <div class="col" style="font-weight: bold; font-size:20px">{{ quantity }}</div>
                    <div class="col" style="text-align: right;"> <q-icon :name="iconName" color="primary"  size="35px"/></div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ label  }}
                    </div>
                </div>
                 
            </q-card-section>
        </q-card>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CardDashboard',
    
    props: {
        quantity: {
            type: Number,
        },
        iconName: {
            type: String,
            required: true
        },

        label: {
            type: String,
            required: true
        },
        
    },
    setup(props) {
        return {
            ...props
        };
    }
});
</script>

<style scoped>
.card {
    background-color: white;
    width: 90%;
   
}
</style>
