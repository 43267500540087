<template>
    <div>
        <div class="row formSectionTitle" v-if="tenantModel">
            <div class="col-9">
                <q-icon color="white" name="dashboard" size="35px" />
                <span>{{ tenantModel.tenant.name }}</span>
            </div>
        </div>
        <br>
        <div class="q-pa-md">
            <div class="row">
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.customers" :key="tenantDashboard.customers" iconName="person_outline" label="Clientes" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.proposals" :key="tenantDashboard.proposals" iconName="library_books" label="Propostas" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.proposalsWithoutFee" :key="tenantDashboard.proposalsWithoutFee" iconName="library_books" label="Propostas sem Taxas" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.proposalsWithoutProcess" :key="tenantDashboard.proposalsWithoutProcess" iconName="library_books" label="Propostas sem processo" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.proposalsWithoutHistory" :key="tenantDashboard.proposalsWithoutHistory" iconName="library_books" label="Propostas sem histórico" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.processes" :key="tenantDashboard.processes" iconName="collections_bookmark" label="Processos" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.documentsProcess" :key="tenantDashboard.documentsProcess" iconName="collections_bookmark" label="Documentos de Processos" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.accountsReceivable" :key="tenantDashboard.accountsReceivable" iconName="vertical_align_top" label="Contas a Receber" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.accountsPayable" :key="tenantDashboard.accountsPayable" iconName="vertical_align_bottom" label="Contas a Pagar" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.paymentsPayable" :key="tenantDashboard.paymentsPayable" iconName="payments" label="Pagamentos registrados" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.paymentsReceivable" :key="tenantDashboard.paymentsReceivable" iconName="payments" label="Recebimentos registrados" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.contacts" :key="tenantDashboard.contacts" iconName="contacts" label="Contatos" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.users" :key="tenantDashboard.users" iconName="person" label="Usuários" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.pricingQuotations" :key="tenantDashboard.pricingQuotations" iconName="library_books" label="Cotações" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.defaultFees" :key="tenantDashboard.defaultFees" iconName="attach_money" label="Taxas padrão" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.attachmentsAccountPayable" :key="tenantDashboard.attachmentsAccountPayable" iconName="attachments" label="Anexos Contas a Pagar" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.attachmentsAccountReceivable" :key="tenantDashboard.attachmentsAccountReceivable" iconName="attachments" label="Anexos Contas a Receber" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.attachmentsPaymentPayable" :key="tenantDashboard.attachmentsPaymentPayable" iconName="attachments" label="Anexos Pagamentos Registrados" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.attachmentsPaymentReceivable" :key="tenantDashboard.attachmentsPaymentReceivable" iconName="attachments" label="Anexos Recebimentos Registrados" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.vessels" :key="tenantDashboard.vessels" iconName="directions_boat" label="Navios" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.suppliers" :key="tenantDashboard.suppliers" iconName="local_shipping" label="Fornecedores" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.vehicles" :key="tenantDashboard.vehicles" iconName="directions_car" label="Veículos" />
                </div>
                <div class="col-4">
                    <card-dashboard :quantity="tenantDashboard.drivers" :key="tenantDashboard.drivers" iconName="perm_identity" label="Motoristas" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import CardDashboard from '../components/CardDashboard.vue'; // Adjust the path to where MyCard.vue is located
import { tenantService } from '../services/tenant.service';
import { dashboardService } from '../services/dashboard.service';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'DashboardView',
    components: {
        CardDashboard
    },

    setup() {
        // Route
        const route = useRoute();

        // Tenant
        const tenantModel = ref({
            tenant: {
                id: 0,
                name: null,
                cnpj: null,
                alias: null,
                active: true
            },
            systemModules: [],
            features: []
        });

        // Dashboard
        const tenantDashboard = ref({
            customers: 0,
            proposals: 0,
            proposalsWithoutFee: 0,
            proposalsWithoutProcess: 0,
            proposalsWithoutHistory: 0,
            processes: 0,
            documentsProcess: 0,
            accountsReceivable: 0,
            accountsPayable: 0,
            paymentsReceivable: 0,
            paymentsPayable: 0,
            contacts: 0,
            users: 0,
            pricingQuotations: 0,
            defaultFees: 0,
            suppliers: 0,
            vehicles: 0,
            drivers: 0,
            attachmentsAccountReceivable: 0,
            attachmentsAccountPayable: 0,
            attachmentsPaymentReceivable: 0,
            attachmentsPaymentPayable: 0,
            vessels: 0
        });

        // Methods
        const getTenant = async () => {
            try {
                const response = await tenantService.get(route.params.tenantId);
                tenantModel.value = response;
                await getDashboard(tenantModel.value.tenant.alias);
            } catch (error) {
                console.error("Error fetching tenant data:", error);
            }
        };

        const getDashboard = async (tenantAlias) => {
            try {
                const response = await dashboardService.getDashboard(tenantAlias);
                // Validate and log the response structure
                if (response) {
                    tenantDashboard.value = response;
                } else {
                    console.error("Invalid dashboard response structure");
                }
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            }
        };

        onMounted(() => {
            getTenant();
        });

        return { tenantModel, tenantDashboard };
    }
});
</script>
<style scoped>
.formSectionTitle {
    background-color: #91a8c7;
    text-align: left;
    padding-left: 50px;
    font-size: 20px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
}

.formSectionTitle span {
    padding-left: 20px;
}
</style>
