
export const utils = {
    
    getApiUrl,
    getStringForBoolean
};

function getApiUrl() {
    if (process.env.NODE_ENV === 'development') {
        return "http://localhost:5081";
    } else {
        // Return empty string in production
        return "";
    }
}

function getStringForBoolean(value) {

    if(value === true){
        return 'Sim'
    }
    else
    {
        return 'Não'
    }
}