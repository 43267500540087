import { utils } from '../helpers/utils';
import { apiHandler } from '../helpers/api-handler';

export const tenantService = {
    create,
    list,
    get,
    update,
    listSystemModules,
    listFeatures,
    listModel,
    updateHistoryStatus,
    updateProposalDate,
    updateProcessDate,
    updateAttachmentDate,
    updateIncidentDate,
    updateFinancialItemsDate,
    updateQuotationDate,
    updateDatesForAllRecords
};

function list() {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/tenant/list')
        .then(response => {
            return response;
    });
}

function listModel() {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/tenant/listmodel')
        .then(response => {
            return response;
    });
}

function listSystemModules() {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/tenant/listSystemModules')
        .then(response => {
            return response;
    });
}

function listFeatures() {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/tenant/listFeatures')
        .then(response => {
            return response;
    });
}

function get(id) {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/tenant/get?id=' + id)
        .then(response => {
            return response;
    });
}

function create(tenant) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/create', tenant)
        .then(response => {
            return response;
    });
}

function updateHistoryStatus(numberOfDays) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/updateHistoryStatusDate?numberOfDays=' + numberOfDays)
        .then(response => {
            return response;
    });
}

function updateProposalDate(numberOfDays) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/updateProposalDate?numberOfDays=' + numberOfDays)
        .then(response => {
            return response;
    });
}

function updateProcessDate(numberOfDays) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/updateProcessDate?numberOfDays=' + numberOfDays)
        .then(response => {
            return response;
    });
}

function updateAttachmentDate(numberOfDays) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/updateAttachmentDate?numberOfDays=' + numberOfDays)
        .then(response => {
            return response;
    });
}

function updateIncidentDate(numberOfDays) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/updateIncidentDate?numberOfDays=' + numberOfDays)
        .then(response => {
            return response;
    });
}

function updateFinancialItemsDate(numberOfDays) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/updateFinancialItemsDate?numberOfDays=' + numberOfDays)
        .then(response => {
            return response;
    });
}

function updateQuotationDate(numberOfDays) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/updateQuotationDate?numberOfDays=' + numberOfDays)
        .then(response => {
            return response;
    });
}

function updateDatesForAllRecords(numberOfDays) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/updateDatesForAllRecords?numberOfDays=' + numberOfDays)
        .then(response => {
            return response;
    });
}


function update(tenant) {

    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenant/update', tenant)
        .then(response => {
            return response;
    });
}