import { utils } from '../helpers/utils';
import { apiHandler } from '../helpers/api-handler';

export const dashboardService = {
    getDashboard
};

function getDashboard(tenantAlias) {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/dashboard/getDashboard?tenantAlias=' + tenantAlias)
        .then(response => {
            return response;
    });
}

