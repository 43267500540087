<template>
    <q-card :style="{ minWidth: cardWidth, backgroundColor: 'white' }">
      <q-card-section>
        <div>
          <div class="row dialogSectionTitle">
            <div class="col-12">
              <span>{{ title }}</span>
            </div>
          </div>
        </div>
        <br>
        <slot></slot>
      </q-card-section>
    </q-card>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      width: {
        type: String,
        default: '700px'
      }
    },
    computed: {
      cardWidth() {
        return this.width || '700px';
      }
    }
  }
  </script>
  <style>
    .dialogSectionTitle {
    background-color: #91a8c7;
    text-align: left;
    padding-left: 50px;
    font-size: 20px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dialogSectionTitle span {
      padding-left: 20px;
  }
  </style>
  