// Function to check the status of a feature flag
function isFeatureEnabled(flagName) {
    switch (flagName) {
        case 'dateAdjustmentFeature':
            return process.env.VUE_APP_ENABLE_DATE_ADJUSTMENT_FEATURE === 'true';
        default:
            return false;
    }
}

export { isFeatureEnabled };
