import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'
import { utils  } from './helpers/utils'
import TitleHeader from './components/TitleHeader.vue'



const app = createApp(App);

//Formatters
app.config.globalProperties.$formatBooleanValue = utils.getStringForBoolean;

//Components
app.component('title-header', TitleHeader);


app.use(router).use(Quasar, quasarUserOptions).mount('#app');