<template>
    <div>
      <TenantList/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
import TenantList from '../components/TenantList.vue';
  
  export default {
    name: 'TenantListView',
    components: {
        TenantList
    }
  }
  </script>