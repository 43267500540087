import { utils } from '../helpers/utils';
import { apiHandler } from '../helpers/api-handler';

export const authService = {
   getLoginUrl,
   getToken,
   logout,
   storeSessionStorage,
   getTokenSessionStorage,
   getAuthenticatedUserSessionStorage,
   getAuthHeadersForUpload
};


function getAuthHeadersForUpload(){
    let tokenInfo = authService.getTokenSessionStorage();
    return [{name: 'Authorization', value: `Bearer ${tokenInfo.id_token}`}];
}

function getLoginUrl() {
    return apiHandler.getApiAnnonymousRequest(utils.getApiUrl() + '/api/auth/getLoginUrl')
        .then(response => {
            return response;
        });
        
}

function getToken(code) {
    return apiHandler.getApiAnnonymousRequest(utils.getApiUrl() + '/api/auth/token?code=' + code)
        .then(response => {
            storeSessionStorage(response, response.user);
            return response.user;
        });
}

function storeSessionStorage(tokenInfo, user){
    localStorage.setItem('tokenInfo', JSON.stringify(tokenInfo));
    localStorage.setItem('user', JSON.stringify(user));
}

function getTokenSessionStorage(){
    return JSON.parse(localStorage.getItem('tokenInfo'));
}

function getAuthenticatedUserSessionStorage(){
    return  JSON.parse(localStorage.getItem('user'));
}

function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('tokenInfo');
}