import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import LayoutAdmin from '../components/LayoutAdmin.vue'
import TenantListView from '../views/TenantListView.vue'
import TenantFormView from '../views/TenantFormView.vue'
import UserListView from '@/views/UserListView.vue'
import UserForm from '@/components/UserForm.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/system',
    component: LayoutAdmin,
    children: [
      {
        path:'dashboard/:tenantId',
        component:DashboardView
      },
      {
        path:'tenantlist',
        component:TenantListView
      },
      {
        path:'tenantform',
        component:TenantFormView
      },
      {
        path:'tenantform/:id',
        component:TenantFormView
      },
      {
        path:'userlist',
        component:UserListView
      },
      {
        path:'userform',
        component:UserForm
      },
      {
        path:'userform/:id',
        component:UserForm
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
