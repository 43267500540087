<template>
    <div>
        <title-header title="TENANT"></title-header>
        <br>
        <q-form @submit="save" class="q-pa-md">
            <div class="row">
                <div class="col-9">
                    <q-input outlined 
                            label="Nome"
                            v-model="tenantModel.tenant.name"
                            :rules="[val => val && val.length > 0 || 'Campo obrigatório']"
                            filled>
                                                
                    </q-input>
                </div>
            </div>
            <div class="row">
                <div class="col-9">
                    <q-input outlined 
                            label="Alias"
                            :readonly="isAliasReadonly"
                            :model-value="formattedAlias"
                            @update:model-value="updateAlias"
                            :rules="[val => val && val.length > 0 || 'Campo obrigatório']"
                            filled>
                                                
                    </q-input>
                </div>
            </div>
            <div class="row">
                <div class="col-9">
                    <q-input outlined label="CNPJ" v-model="tenantModel.tenant.cnpj"
                             :mask="'##.###.###/####-##'" />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-9">
                    <q-select
                        clearable
                        filled
                        v-model="tenantModel.systemModules"
                        multiple
                        :options="systemModulesOptions"
                        option-value="id"
                        option-label="name"
                        label="Módulos"
                        style="width: 250px"
                    />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-9">
                    <q-select
                        clearable
                        filled
                        v-model="tenantModel.features"
                        multiple
                        :options="featureOptions"
                        option-value="id"
                        option-label="name"
                        label="Features"
                        style="width: 250px"
                    />
                </div>
            </div>
            <div class="row">
                  <div class="col-9" style="text-align:left">
                      <q-toggle :false-value="false"
                                label="Ativo"
                                :true-value="true"
                                :color="tenantModel.tenant.active?'green':'red'"
                                v-model="tenantModel.tenant.active"
                                keep-color />
                  </div>
              </div>
              <br>
            <q-separator />
            <br>
            <div class="row items-center">
                <div class="col-9" style="padding-top: 10px; text-align:center">
                    <q-btn color="blue" label="Salvar" type="submit" style="margin-right: 5px;" />
                    <q-btn color="grey" label="Fechar" @click="cancel()" />
                </div>
            </div>
        </q-form>
    </div>
    
</template>
<script>
import { tenantService } from '@/services/tenant.service';
import { reactive, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';


export default{
    setup(){
        //Router
        const route = useRoute();
        const router = useRouter();
        //Data
        const tenantModel = reactive({
            tenant: {
                id: 0,
                name: null,
                cnpj: null,
                alias: null,
                active: true
            },
            systemModules: [],
            features: []
        });

        const systemModulesOptions = ref([]);

        const featureOptions = ref([]);

        const isAliasReadonly = ref(false);

        // Computed property for alias
        const formattedAlias = computed({
            get() {
                return tenantModel.tenant.alias;
            },
            set(value) {
                tenantModel.tenant.alias = transformInput(value);
            }
        });

        // Function to transform input (remove special characters and convert to lowercase)
        function transformInput(value) {
            return value
                .replace(/[^a-zA-Z0-9]/g, '') // Remove all special characters
                .toLowerCase(); // Convert to lowercase
        }

        // Function to handle updates to alias
        function updateAlias(value) {
            formattedAlias.value = value;
        }

        const cancel = () => {
            router.push('/system/tenantlist') ; 
        }

        const  save = () => {
            if (route.params.id) {
                tenantService.update(tenantModel)
                .then(() => {
                    router.push('/system/tenantlist') ;     
                });
            }
            else
            {
                tenantService.create(tenantModel)
                .then(() => {
                    router.push('/system/tenantlist') ;     
                });
            }
            
        };

        onMounted(() => {
            if (route.params.id) {
                tenantService.get(route.params.id)
                    .then((response) => {
                        Object.assign(tenantModel,response);
                        isAliasReadonly.value = true;
                    });
            }

            tenantService.listSystemModules()
                    .then((response) => {
                        Object.assign(systemModulesOptions.value,response);
                    });
            
             tenantService.listFeatures()
                    .then((response) => {
                        Object.assign(featureOptions.value,response);
                    });
        });
        

        return {save, cancel, tenantModel, formattedAlias, updateAlias, isAliasReadonly, systemModulesOptions, featureOptions}
    }
}

</script>
