<template>
    <div>
        <title-header title="USUÁRIO"></title-header>
        <br>
        <q-form @submit="save" class="q-pa-md">
            <div class="row">
                <div class="col-9">
                    <q-input outlined 
                            label="Nome"
                            v-model="userRequest.appUser.name"
                            :rules="[val => val && val.length > 0 || 'Campo obrigatório']"
                            filled>
                                                
                    </q-input>
                </div>
            </div>
            <div class="row">
                <div class="col-9">
                    <q-input outlined 
                            label="Email"
                            v-model="userRequest.appUser.email"
                            type="email"
                            :rules="[validateEmail]" 
                            filled>
                                                
                    </q-input>
                </div>
            </div>
            <br>
            <q-separator />
            <br>
            <div class="row">
                <div class="col-3">
                    <q-select
                        filled
                        v-model="user_tenant_selected"
                        :options="options_tenant"
                        option-value="id"
                        option-label="name"
                        label="Tenants"
                        
                    />
                </div>
                <div class="col-3 offset-1">
                    <q-select
                        filled
                        v-model="user_profile_selected"
                        :options="options_user_profiles"
                        option-value="id"
                        option-label="name"
                        label="Perfil"
                       
                    />
                </div>
                <div class="col-1">
                    <q-icon name="add_circle" size="35px" style="padding-top:10px; padding-left:15px; cursor:pointer;" @click="addTenant()"/>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-9">
                    <q-table
                           
                        flat bordered
                        class="my-sticky-header-table"
                        dense
                        :rows="userRequest.tenantProfileList"
                        :columns="columns_user_tenant"
                        :pagination="{ rowsPerPage: 0 }"
                        >
                        <template v-slot:body="props">
                            <q-tr :props="props">
                            <q-td key="tenant" :props="props">
                                {{ props.row.tenant.name }}
                            </q-td>
                            <q-td key="profile" :props="props">
                                {{ props.row.userProfile.name}}
                            </q-td>
                            <q-td key="operations" :props="props">
                                <q-icon color="red"
                                         name="delete"
                                         style="font-size: 2.0em" @click="removeTenant(props.row.tenant.id)" />
                            </q-td>
                            </q-tr>
                        </template>
                        </q-table>
                </div>
            </div>
            <br>
            <q-separator />
            <br>
            <div class="row">
                  <div class="col-9" style="text-align:left">
                      <q-toggle :false-value="false"
                                label="Ativo"
                                :true-value="true"
                                :color="userRequest.appUser.active?'green':'red'"
                                v-model="userRequest.appUser.active"
                                keep-color />
                  </div>
              </div>
              <br>
            <q-separator />
            <br>
            <div class="row items-center">
                <div class="col-9" style="padding-top: 10px; text-align:center">
                    <q-btn color="blue" label="Salvar" type="submit" style="margin-right: 5px;" />
                    <q-btn color="grey" label="Fechar" @click="cancel()" />
                </div>
            </div>
        </q-form>
    </div>
    
</template>
<script>
import { userService } from '@/services/user.service';
import { reactive, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { onMounted } from 'vue';
import { tenantService } from '@/services/tenant.service';
import { useQuasar } from 'quasar';


export default{
    setup(){
        //Quasar 
        const $q = useQuasar();
        //Router
        const route = useRoute();
        const router = useRouter();
        //Data
        const userRequest = reactive({
            appUser: {
                id: 0,
                name: null,
                email: null,
                active: true
            },
            tenantProfileList: []
            
        });

        const user_tenant_selected = ref(null);
        const user_tenants = ref([]);
        const options_tenant = ref([]);

        const options_user_profiles = ref([]);
        const user_profile_selected = ref(null);
        const columns_user_tenant = [
            { name: 'tenant', label: 'Tenant', align: 'center', field: row => row.tenant.name, format: val => `${val}`, sortable: true},
            { name: 'profile', label: 'Perfil', align: 'center', field: row => row.userProfile.name, sortable: true },
            { name: 'operations', label: '', field: '', sortable: true, align: "center" }
        ]

        //Methods
                
        const validateEmail = (value) => {
            // eslint-disable-next-line no-useless-escape
            const pattern = /^(([^<>()\[\]\\.,;:\s@"']+(\.[^<>()\[\]\\.,;:\s@"']+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Email inválido';
        }        

        const cancel = () => {
            router.push('/system/userlist') ; 
        }

        const  save = () => {
            if (route.params.id) {
                userService.update(userRequest)
                .then(() => {
                    router.push('/system/userlist') ;     
                });
            }
            else
            {
                userService.create(userRequest)
                .then(() => {
                    router.push('/system/userlist') ;     
                });
            }
            
        };

        const addTenant = () => {

            if(user_tenant_selected.value === null){
                $q.notify({
                    message: 'Tenant é obrigatório',
                    position: 'top'
                });
            }

            if(user_profile_selected.value === null){
                $q.notify({
                    message: 'Perfil é obrigatório',
                    position: 'top'
                });
            }

            if (!userRequest.tenantProfileList.some(existingTenant => existingTenant.tenant.id === user_tenant_selected.value.id)) {
                userRequest.tenantProfileList.push({tenant: user_tenant_selected.value, userProfile: user_profile_selected.value})
                } else {
                    $q.notify({
                    message: 'Tenant já registrado para este usuário(a)',
                    position: 'top'
                });
                }

            user_profile_selected.value = null;
            user_tenant_selected.value = null;
           
        }

        const removeTenant = (tenantId) => {
            var filteredArray = userRequest.tenantProfileList.filter(function(obj) {
                return obj.tenant.id !== tenantId;
            });

            userRequest.tenantProfileList = filteredArray;
        }

        onMounted(() => {

            userService.getUserProfiles()
                .then((response) => {
                    options_user_profiles.value = response;
                });

            tenantService.list()
                .then((response)=> {
                    options_tenant.value = response;
                });

            if (route.params.id) {
                userService.get(route.params.id)
                    .then((response) => {
                        Object.assign(userRequest,response);
                    });
            }
        });
        

        return {
            save, 
            cancel, 
            userRequest, 
            validateEmail, 
            user_tenants, 
            options_tenant, 
            user_tenant_selected, 
            options_user_profiles,
            user_profile_selected,
            addTenant,
            columns_user_tenant,
            removeTenant
        }
    }
}

</script>
