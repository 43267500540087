<template>
    <div class="login-container">
      <q-card class="login-box">
        <q-card-section class="q-pa-md">
          <img src="../assets/vertical-logo.png" alt="Logo" class="logo">
          <q-btn label="Acessar" unelevated color="primary" class="q-mt-md btn-login" @click="redirectLoginPage"/>
        </q-card-section>
      </q-card>
    </div>
  </template>
  
  <script>
  import { authService } from '../services/auth.service';
  import { onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';


  export default {
    setup() {
      const route = useRoute();
      const router = useRouter();
      const redirectLoginPage = () => {
        authService.getLoginUrl()
              .then(response => {
                  window.location = response.authorizationUrl;
              });
      };

      const  handleAuthorizationCode = () => {

          authService.getToken(route.query.code)
            .then((user) => {
              if(user != null){
                router.push('/system/tenantlist') ;
              }         
            });
      };

      onMounted(() => {
        if (route.query.code) {
          handleAuthorizationCode();
        }
      });

      return { redirectLoginPage, handleAuthorizationCode };
    }
  }
  </script>
  
  <style scoped>
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Prevents scrollbars if not necessary */
  }
  
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw; /* Ensures it covers full viewport width */
    height: 100vh; /* Ensures it covers full viewport height */
    background-color: #e75f49; /* Replace with any color you prefer */
  }
  
  .login-box {
    width: 90%;
    max-width: 400px;
    border-radius: 15px; /* Rounded borders */
    background-color: white;
  }
  
  .logo {
    display: block;
    margin: 0 auto;
    width: 50%; /* Adjust size as needed */
    height: auto;
  }

  .btn-login {
    display: block;
    margin: 0 auto;
    width: 50%; /* Adjust size as needed */
    height: auto;
    background-color: #003981 !important
  }
  </style>
  