<template>
    <div>
        <q-layout view="lHh Lpr lFf">
        <q-header>
            <q-toolbar class="bg-brand"
                       :glossy="$q.theme === ''"
                       :inverted="$q.theme === 'ios'"
                       style="padding:25px;">
                <q-btn flat
                       dense
                       round
                       @click="leftDrawerOpen = !leftDrawerOpen"
                       aria-label="Menu">
                    <q-icon name="menu" />
                </q-btn>

                <div class="row" style=" width:100%">
                    <div class="q-pa-xs col-xs-12 col-sm-6 col-md-6" style="text-align:left; ">
                        <img src="../assets/logo.png"
                             style="width:170px;padding-left:25px;">
                    </div>
                    <div class="q-pa-xs col-xs-12 col-sm-6 col-md-6 " style="text-align:right;">
                        <div class="row">

                            <div class="col-12" style="padding-right:30px;">
                                <q-btn-dropdown stretch flat style="padding-right:15px;">
                                    <q-list>
                                        <q-item clickable v-close-popup tabindex="0">
                                            <q-item-section avatar>
                                                <q-avatar icon="https" color="red" text-color="white" />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>Sair</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-btn-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

            </q-toolbar>
        </q-header>
        <q-drawer v-model="leftDrawerOpen"
                  :content-class="$q.theme === 'mat' ? 'white' : null">

            <div class="q-pa-md" style="max-width: 350px">
                <q-list bordered class="rounded-borders admin-menu">
                    <q-item clickable to="/system/tenantlist">
                        <q-item-section avatar>
                            <q-icon color="primary" name="add_home" />
                        </q-item-section>
                        <q-item-section style="padding-right:40px;">Tenants</q-item-section>
                    </q-item>
                    <q-item clickable to="/system/userlist">
                        <q-item-section avatar>
                            <q-icon color="primary" name="people_alt" />
                        </q-item-section>
                        <q-item-section style="padding-right:40px;">Usuários</q-item-section>
                    </q-item>
                </q-list>
            </div>
        </q-drawer>
        <q-page-container>
            <router-view />
        </q-page-container>
        
    </q-layout>
    </div>
   
</template>
<script>
import { ref } from 'vue';
import { isFeatureEnabled } from '../helpers/feature-flags';


export default{
    setup(){
        const leftDrawerOpen = ref(false);

        return  {leftDrawerOpen, isFeatureEnabled}
    }
}
</script>